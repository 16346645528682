<template>
  <form @submit.prevent="onsubmit" class="login">
    <div class="row">
      <va-notification class="flex md10" v-if="currState=='success'" color="success">
        {{ currMsg }}
      </va-notification>
      <va-notification class="flex md10" v-if="currState=='error'" color="danger">
        {{ currMsg }}
      </va-notification>
    </div>

    <div class="row mb-2">
      <va-input
        v-model="email"
        type="email"
        :label="$t('auth.email')"
        :error="!!emailErrors.length"
        :error-messages="emailErrors"
      />
    </div>
    <div v-if="isChecking" class="d-flex justify--center mt-3">
      <looping-rhombuses-spinner
        :animation-duration="2500"
        :rhombus-size="15"
        color="#000000"
      />
    </div>
    <div v-else class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0">{{ $t('auth.resetPassword') }}</va-button>
    </div>
  </form>
</template>

<script>
import { LoopingRhombusesSpinner } from 'epic-spinners'

export default {
  name: 'recover-password',
  components: {
    LoopingRhombusesSpinner,
  },
  data () {
    return {
      email: '',
      emailErrors: [],
      currState: '',
      currMsg: '',
      isChecking: false,
      timeoutID: '',
    }
  },
  methods: {
    onsubmit () {
      if (!this.email) {
        this.emailErrors = [this.$t('auth.errEmailRequired')]
        return
      }

      this.isChecking = true
      this.timeoutID = setTimeout(this.loginTimeout, 5000)

      this.$userauth.requestPwdReset({
        email: this.email,
        regURL: window.location.href,
        emailSubject: this.$t('auth.emailSubjPwdReset'),
        emailGreeting: this.$t('auth.emailGreeting'),
        emailText: this.$t('auth.emailTxtRegister1'),
        emailFooter: this.$t('auth.emailTxtRegister2'),
      })
        .then(result => {
          this.okResponse()
        })
        .catch(err => {
          console.log(err)
          this.okResponse()
        })
    },
    loginTimeout () {
      this.isChecking = false
      this.currMsg = this.$t('auth.errTimeout')
      this.currState = 'error'
    },
    okResponse () {
      clearTimeout(this.timeoutID)
      this.isChecking = false
      this.currMsg = this.$t('auth.regMailSentChange')
      this.currState = 'success'
      this.email = ''
    },
  },
}
</script>

<style lang="scss">
</style>
